<script lang="ts" setup>
import { FormalInput } from 'webcc-ui-components'

const formValues = inject<{ ownerNumber: string }>('formValues', {
  ownerNumber: '',
})

withDefaults(
  defineProps<{
    validation?: string
  }>(),
  {
    validation: 'required|alphanum|length:7,7',
  },
)

const emit = defineEmits<{
  (e: 'paste', event: ClipboardEvent): void
}>()

const { handlePaste } = usePasteClipboard()

const normalizeValue = (value: string) => {
  return value.replace(/[^a-zA-Z0-9]+/g, '').toUpperCase()
}

const onKeyUp = (e) => {
  formValues.ownerNumber = normalizeValue(e.target.value)
}

const onPaste = handlePaste((text, evt) => {
  formValues.ownerNumber = normalizeValue(text)
  emit('paste', evt)
})
</script>

<template>
  <FormalInput
    name="ownerNumber"
    :label="$t('components.common.ownerNumber')"
    maxlength="7"
    :validation="validation"
    :validation-messages="{
      required: $t('components.validation.fieldRequired'),
      alphanum: $t('components.login.symbolError'),
      length: $t('components.validation.fieldLength'),
    }"
    v-bind="$attrs"
    type="text"
    placeholder="AA00000"
    autocomplete="username"
    @paste="onPaste"
    @keyup="onKeyUp"
  />
</template>

<style scoped>
::placeholder {
  font-size: 14px;
  font-weight: 600;
}
</style>
